import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material/styles'

declare module "@mui/material/styles" {
  interface Palette {
    extraBlue: PaletteColor
    extraYellow: PaletteColor
  }
  interface PaletteOptions {
    extraBlue: PaletteColorOptions
    extraYellow: PaletteColorOptions
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    extraBlue: true
    extraYellow: true
  }
}

const { palette } = createTheme()
const theme = createTheme({
  palette: {
    primary: {
      main: '#242424',
      dark: '#000000',
      light: '#E9E9E9',
    },
    secondary: {
      main: '#FFC429',
      dark: '#CA9C39',
      light: '#BBBBBB',
    },
    extraBlue: palette.augmentColor({ color: { main: '#3C6EB4' } }),
    extraYellow: palette.augmentColor({ color: { main: '#CC9D21' } }),
  },
  typography: {
    h1: {
      fontFamily: "'industry-inc', sans-serif",
      fontSize: '3rem',
      '@media (min-width:600px)': {
        fontSize: '4rem',
      },
    },
    h2: {
      fontFamily: "'industry-inc', sans-serif",
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontFamily: "'industry-inc', sans-serif",
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h4: {
      fontFamily: "'industry-inc', sans-serif",
      fontSize: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontFamily: "'industry-inc', sans-serif",
      fontSize: '1.1rem',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    h6: {
      fontFamily: "'proxima-nova', sans-serif",
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.1rem',
      },
    },
    body1: {
      fontFamily: "'proxima-nova', sans-serif",
      fontSize: '0.8rem',
    },
    body2: {
      fontFamily: "'proxima-nova', sans-serif",
    },
    button: {
      fontFamily: "'proxima-nova', sans-serif",
      lineHeight: '1.1rem',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
        },
      },
    },
  },
})

export default theme
