import axios from 'axios'
import { API_URLS } from '../config/constants'

interface RequestConfig {
  url: string
  method?: string
  data?: any
  params?: any
  success?: Function
  failure?: Function
}

export const axiosHelper = ({
  url,
  method = 'get',
  data = {},
  params = {},
  success = (r: any) => console.log(r),
  failure = (e: any) => console.log(e),
}: RequestConfig) => {
  const baseURL =
    API_URLS[
      (import.meta.env.VITE_HOST_ENV as keyof typeof API_URLS) ||
        ('local' as keyof typeof API_URLS)
    ]
  const headers = {}
  axios({ url, baseURL, headers, method, data, params })
    .then(res => success(res))
    .catch(res => failure(res))
}
