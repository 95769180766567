const DRAWER_WIDTH = 300
const LOCAL_STORAGE_KEY = 'BAF-sales-tools-ui'

const SEARCH_LIST = {
  all: [
    { label: 'Design Guide', id: 'designguide' },
    { label: 'Fan Schedule Builder', id: 'designwizard' },
    { label: 'Heat Index Hours Calculator', id: 'heatindexcalc' },
    { label: 'Submittals', id: 'submittals' },
  ],
  calc: [{ label: 'Heat Index Hours Calculator', id: 'heatindexcalc' }],
  destools: [
    { label: 'Design Guide', id: 'designguide' },
    { label: 'Fan Schedule Builder', id: 'designwizard' },
    { label: 'Submittals', id: 'submittals' },
  ],
}

const SUBMITTALS_VERSION = '18.0'
const SCHEDULE_BUILDER_VERSION = '3.0'

const BAF_COLOR_OPTIONS = {
  'Powerfoil Yellow': '#f2c805',
  'Valor Red': '#aa212a',
  'Royal Blue': '#09619a',
  'Oil-Rubbed Bronze': '#463828',
  'Alpine White': '#b8b8b8',
  'Gunmetal Gray': '#6d7372',
  'Stealth Black': '#3d4546',
  'Vintage Silver': '#adb1b2',
}

const API_URLS = {
  local: 'http://127.0.0.1:4040',
  development: 'https://sales-tools-api-dev.bigassfans.com',
  staging: 'https://sales-tools-api-staging.bigassfans.com',
  production: 'https://sales-tools-api.bigassfans.com',
}

const FACILITY_TYPES = ['Industrial', 'Commercial', 'Residential']

const MONTHS = [
  { name: 'January', days: 31 },
  { name: 'February', days: 28 },
  { name: 'March', days: 31 },
  { name: 'April', days: 30 },
  { name: 'May', days: 31 },
  { name: 'June', days: 30 },
  { name: 'July', days: 31 },
  { name: 'August', days: 31 },
  { name: 'September', days: 30 },
  { name: 'October', days: 31 },
  { name: 'November', days: 30 },
  { name: 'December', days: 31 },
]

const PRIMARY_USES = [
  'Agriculture',
  'Automotive Service',
  'Aviation',
  'Education',
  'Fitness',
  'Government',
  'Grocery',
  'Hospitality',
  'Manufacturing',
  'Office',
  'Public Spaces',
  'Restaurant/Bar',
  'Retail',
  'Warehouse/Distribution',
  'Worship Facilities',
]

const STATES = [
  'Alaska',
  'Alabama',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'District of Colombia',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Missouri',
  'Mississippi',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Calgary, AB, CA',
  'Vancouver, BC, CA',
  'Winnipeg, MB, CA',
  'Moncton, NB, CA',
  "St. John's, NL, CA",
  'Edmonton, AB, CA',
  'Halifax, NS, CA',
  'Ottawa, ON, CA',
  'Toronto, ON, CA',
  'Charlottetown, PE, CA',
  'Montreal, QC, CA',
  'Regina, SK, ON',
]

export {
  API_URLS,
  BAF_COLOR_OPTIONS,
  DRAWER_WIDTH,
  FACILITY_TYPES,
  LOCAL_STORAGE_KEY,
  MONTHS,
  PRIMARY_USES,
  SCHEDULE_BUILDER_VERSION,
  SEARCH_LIST,
  STATES,
  SUBMITTALS_VERSION,
}
