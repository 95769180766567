import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material/styles'

import './index.css'

import 'react-tooltip/dist/react-tooltip.css'
import Layout, { NoMatch } from 'features/layout'

import theme from './config/theme'

const FanScheduleBuilderStandalone = async () => {
  const Component = (await import('features/fan-schedule-builder')).Component
  return { Component: () => <Component standalone/> }
}
const FanScheduleBuilder = async () => {
  const Component = (await import('features/fan-schedule-builder')).Component
  return { Component: () => <Component showVersion/> }
}
const SubmittalsStandalone = async () => {
  const Submittals = (await import('features/submittals')).Component
  return { Component: () => <Submittals standalone/> }
}
const ArchivedProjects = async () => {
  return { Component: (await import('features/projects/ArchivedProjects')).default }
}
const Projects = async () => {
  return { Component: (await import('features/projects/Projects')).default }
}
const ProjectResults = async () => {
  return { Component: (await import('features/projects/ProjectResults')).default }
}

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/standalone/">
      <Route
        path="fan-schedule-builder"
        lazy={FanScheduleBuilderStandalone}
      />
      <Route path="submittals" lazy={SubmittalsStandalone} />
    </Route>,
    <Route path="/" element={<Layout />}>
      <Route index lazy={() => import('features/home')}/>
      <Route path="design-guide" lazy={() => import('features/design-guide')} />
      <Route
        path="fan-schedule-builder"
        lazy={FanScheduleBuilder}
      />
      <Route path="submittals" lazy={() => import('features/submittals')} />
      <Route path="heat-index-hours-calculator" lazy={() => import('features/heat-index-calc')} />
      <Route path="heat-index-calculator" lazy={() => import('features/heat-index-calc')} />
      <Route
        path="extension-tube-calc"
        lazy={() => import('features/extension-tube-calc')}
      />
      <Route
        path="employee-savings-calc"
        lazy={() => import('features/employee-savings-calc')}
      />
      <Route path="evap-sizing-tool" lazy={() => import('features/evap-sizing-tool')} />
      <Route
        path="fan-energy-estimator"
        lazy={() => import('features/fan-energy-estimator')}
      />
      <Route
        path="heat-savings-estimator"
        lazy={() => import('features/heat-savings-estimator')}
      />
      <Route path="destrat-tool" lazy={() => import('features/destrat-tool')} />
      <Route
        path="cost-of-ownership-calc"
        lazy={() => import('features/cost-of-ownership-calc')}
      />
      <Route
        path="product-dimensions"
        lazy={() => import('features/product-dimensions')}
      />
      <Route path="operational-loads" lazy={() => import('features/operational-loads')} />
      <Route path="ac-savings-calc" lazy={() => import('features/ac-savings-calc')} />
      <Route path="safety-clearance-design" lazy={() => import('features/safety-clearance-design')} />
      <Route path="projects" lazy={Projects} />
      <Route path="projects/:id" lazy={ProjectResults} />
      <Route path="projects/archived" lazy={ArchivedProjects} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  ])
)

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        cacheLocation="localstorage"
        useCookiesForTransactions={true}
        useRefreshTokens={true}
        domain={import.meta.env.VITE_AUTH0_DOMAIN || ''}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <RouterProvider router={router}/>
      </Auth0Provider>
    </ThemeProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
